export default {
  name: "bar",
  components: {},
  props: {
    value: {
      type: String,
      default: "0"
    },
    title: {
      type: String,
      default: "-"
    },
    data: {
      type: Number,
      default: 0
    },
    color: {
      type: String
    }
  },
  data() {
    return {};
  },
  mounted() {}
};