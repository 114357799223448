var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-progress"
  }, [_c('h5', {
    staticClass: "mb"
  }, [_vm._v(" " + _vm._s(_vm.value) + " "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(_vm._s(_vm.title))])]), _c('div', {
    staticClass: "progress"
  }, [_c('Progress', {
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "percent": _vm.data,
      "stroke-width": 5,
      "stroke-color": _vm.color,
      "hide-info": ""
    }
  }), _c('span', {
    staticClass: "progress-value"
  }, [_vm._v(_vm._s(_vm.data) + "%")])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };